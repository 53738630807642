export const COMMON_THEME_VALUES = {
  fontSize: {
    xxxl: '3.75rem',
    xxl: '2.125rem',
    xl: '1.5rem',
    l: '1.25rem',
    m: '1rem',
    s: '0.875rem',
    xs: '0.75rem',
    xxs: '0.625rem'
  },
  fontWeight: {
    regular: 'regular',
    bold: 'bold'
  },
  fontFamily: {
    lato: 'Lato',
    sfpro: '-apple-system, BlinkMacSystemFont, sans-serif'
  },
  border: {
    hairLine: '1px',
    thin: '2px',
    heavy: '4px'
  },
  borderRadius: {
    s: '4px',
    m: '16px',
    circular: '50%'
  },
  padding: {
    nano: '0.25rem',
    micro: '0.5rem',
    xxxs: '0.75rem',
    xxs: '1rem',
    xs: '1.5rem',
    sm: '2rem',
    md: '2.5rem',
    lg: '3rem',
    xl: '3.5rem',
    xxl: '5rem'
  },
  forgotPassword: {
    imageHeight: '55px',
    imageWidth: '55px'
  }
};
