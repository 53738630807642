import React from 'react';
import QRCode from 'react-qr-code';
import { isIOS, isAndroid } from 'react-device-detect';

import CardHeader from '../../components/CardHeader';
import { ContainerCard, CardBody, SubHeader, QRCodeContainer } from './style';

const APPS = [
  {
    app: 'checkingin',
    playStoreLink: 'checkingin',
    appStoreLink: 'checkingin-for-self-awareness/id1465984722'
  },
  {
    app: 'tlaamin',
    playStoreLink: 'tlaamin',
    appStoreLink: 'ʔəms-nəm/id6475880774'
  },
  {
    app: 'sqewlets',
    playStoreLink: 'sqewlets',
    appStoreLink: 'qey%C3%A9x/id6475880686'
  },
  {
    app: 'squamish',
    playStoreLink: 'squamish',
    appStoreLink: 'ta-skwalwentn/id6449235625'
  },
  {
    app: 'stzuminus',
    playStoreLink: 'stzuminusapp',
    appStoreLink: 'stzuminus/id6677057321'
  }
];

const Deeplink = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const app = queryParameters.get('app') ?? 'checkingin';
  const content = queryParameters.get('content');
  const contentId = queryParameters.get('contentId');

  const { playStoreLink, appStoreLink } = APPS.find((i) => i.app === app);

  if ((isIOS || isAndroid) && app) {
    window.location.href = `${app}://${content}/${contentId}`;

    const androidAppStoreLink = `https://play.google.com/store/apps/details?id=com.${playStoreLink}`;
    const iosAppStoreLink = `itms-apps://apps.apple.com/app/${appStoreLink}}`;

    const fallbackLink = isAndroid ? androidAppStoreLink : iosAppStoreLink;
    setTimeout(() => {
      if (document.hasFocus()) {
        window.location = fallbackLink;
      }
    }, 1000);
  }

  return (
    <ContainerCard>
      <CardHeader title="Redirect to content" />
      <CardBody>
        <div style={{ marginTop: '32px' }}>
          <SubHeader>Download the app</SubHeader>
        </div>

        <div style={{ display: 'inline-block' }}>
          <a
            style={{ color: 'transparent' }}
            href={`https://apps.apple.com/br/app/${appStoreLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.ibb.co/Pcc8CBy/download-apple-store.png"
              alt="download-apple-store"
              border="0"
            />
          </a>
          <a
            href={`https://play.google.com/store/apps/details?id=com.${playStoreLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.ibb.co/z7WbnzS/download-play-store.png"
              alt="download-play-store"
              border="0"
            />
          </a>
        </div>

        <div style={{ marginTop: '32px' }}>
          <SubHeader>Open it in smartphone</SubHeader>
        </div>

        <QRCodeContainer>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={window.location.href}
            viewBox="0 0 256 256"
          />
        </QRCodeContainer>
      </CardBody>
    </ContainerCard>
  );
};

export { Deeplink };
